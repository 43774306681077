<template>
	<div id="app" class="bg-light">
		<transition :duration="{ enter: 250, leave: 150 }"
				enter-active-class="animated fadeIn faster"
				leave-active-class="animated fadeOut faster" 
				mode="out-in">
			<router-view />
		</transition>
	</div>
</template>

<script>

	export default {
		name: 'App', 
		components: {
			
		},
		data() {
			return {
				
			}
		},
		watch: {
			
		},
		created() {
			
		}
	}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,700;0,900;1,300&display=swap');
@import "@/assets/css/_custom.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import './assets/css/font-awesome.css';
// @import './assets/css/animate_3.7.2.min.css';

@font-face {
  font-family: MacPro;
  src: url('@/assets/webfonts/MarkPro.otf');
}

[v-cloak] {
	display: none;
}

body {
	font-family: 'MacPro' !important;
}
a:hover {
	text-decoration: none;
} 
.cursor-pointer {
	cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1.5s
}

.fade-enter,
.fade-leave-to {
    opacity: 0
}
</style>
