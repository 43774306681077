import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "HomeView" */ '../pages/HomeView.vue'),
  },
  {
    path: '/read-participations',
    name: 'rp',
    component: () => import(/* webpackChunkName: "ReadParticipations" */ '../pages/ReadParticipations.vue'),
  },
  {
    path: '/reset-participations',
    name: 'resp',
    async beforeEnter(to, from, next) {
      await fetch(process.env.VUE_APP_API_URL+ 'process.php?resetParticipation=1', {
				method: 'GET',
			});
      next({name: 'home'});
    }
  },
  
  { path: '*', redirect: { name: 'home' } },
  // {
  //   path: '/chat/:room_id/:passcode',
  //   name: 'chat',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../pages/ChatView.vue')
  // }
]


const router = new VueRouter({
  mode: process.env.VUE_APP_ROUTER_MODE,
  base: process.env.VUE_APP_BASE_URL,
  routes: routes
});

export default router
