import Vue from 'vue'
import App from './App.vue'
import router from './router'

// check src/client/node_modules/bootstrap-vue/src/index.js
import { 
	LayoutPlugin,
	ButtonPlugin,
	// FormGroupPlugin,
	// FormPlugin,
	// FormInputPlugin,
	// TablePlugin,
	// ModalPlugin,
	// FormSelectPlugin,
	// SpinnerPlugin,
	// InputGroupPlugin,
	// FormRadioPlugin,
	// ToastPlugin,
	// PaginationPlugin,
	// FormCheckboxPlugin,
	// TabsPlugin,
	// CardPlugin,
	// TablePlugin,
	// FormTextareaPlugin,
	// FormDatepickerPlugin,
	// AlertPlugin,
	// DropdownPlugin,
	// FormFilePlugin,
} from 'bootstrap-vue'


Vue.config.productionTip = false

// check src/client/node_modules/bootstrap-vue/src/index.js
// other elements that you may need
// check src/client/node_modules/bootstrap-vue/src/index.js
// other elements that you may need
Vue.use(LayoutPlugin)
// Vue.use(FormTextareaPlugin)
// Vue.use(FormDatepickerPlugin)
// Vue.use(TablePlugin)
// Vue.use(TabsPlugin)
// Vue.use(CardPlugin)
// Vue.use(FormGroupPlugin)
// Vue.use(FormCheckboxPlugin)
// Vue.use(FormInputPlugin)
// Vue.use(FormRadioPlugin)
Vue.use(ButtonPlugin)
// Vue.use(FormSelectPlugin)
// Vue.use(ModalPlugin)
// Vue.use(FormFilePlugin)
// Vue.use(FormPlugin)
// Vue.use(SpinnerPlugin)
// Vue.use(InputGroupPlugin)
// Vue.use(ToastPlugin)
// Vue.use(PaginationPlugin)
// Vue.use(AlertPlugin)
// Vue.use(DropdownPlugin)

// Vue.http.interceptors.push(function (request, next) {
//   if (!request.headers.has('Authorization') && window.localStorage.getItem('token') !== null) {
//     request.headers.set('Authorization', window.localStorage.token);
//   }
//   next()
// });

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')
